.contain {
  position: relative;
  margin: 155px auto;
  width: 255px;
}

.load {
  position: absolute;
}
.load ellipse {
  transform-origin: center;
}
.load:nth-of-type(1) ellipse {
  stroke: #ff5733; /* Bright Red */
  cx: 25px;
  stroke-width: 3px;
  -webkit-animation: jump 600ms infinite ease-in-out;
  animation: jump 600ms infinite ease-in-out;
  opacity: 0.7;
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}
.load:nth-of-type(2) ellipse {
  stroke: #4caf50; /* Bright Green */
  cx: 65px;
  stroke-width: 3px;
  -webkit-animation: jump 600ms infinite ease-in-out;
  animation: jump 600ms infinite ease-in-out;
  opacity: 0.7;
  -webkit-animation-delay: 75ms;
  animation-delay: 75ms;
}
.load:nth-of-type(3) ellipse {
  stroke: #42a5f5; /* Bright Blue */
  cx: 105px;
  stroke-width: 3px;
  -webkit-animation: jump 600ms infinite ease-in-out;
  animation: jump 600ms infinite ease-in-out;
  opacity: 0.7;
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}
.load:nth-of-type(4) ellipse {
  stroke: #ffd700; /* Bright Yellow */
  cx: 145px;
  stroke-width: 3px;
  -webkit-animation: jump 600ms infinite ease-in-out;
  animation: jump 600ms infinite ease-in-out;
  opacity: 0.7;
  -webkit-animation-delay: 225ms;
  animation-delay: 225ms;
}
.load:nth-of-type(5) ellipse {
  stroke: #ff69b4; /* Bright Pink */
  cx: 185px;
  stroke-width: 3px;
  -webkit-animation: jump 600ms infinite ease-in-out;
  animation: jump 600ms infinite ease-in-out;
  opacity: 0.7;
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}
.load:nth-of-type(6) ellipse {
  fill: #ffa500; /* Bright Orange */
  opacity: 0.05;
  rx: 0;
  ry: 0;
  cx: 25px;
  cy: 48px;
  -webkit-animation: shadow 600ms infinite ease-in-out;
  animation: shadow 600ms infinite ease-in-out;
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}
.load:nth-of-type(7) ellipse {
  fill: #8a2be2; /* Bright Purple */
  opacity: 0.05;
  rx: 0;
  ry: 0;
  cx: 65px;
  cy: 48px;
  -webkit-animation: shadow 600ms infinite ease-in-out;
  animation: shadow 600ms infinite ease-in-out;
  -webkit-animation-delay: 75ms;
  animation-delay: 75ms;
}
.load:nth-of-type(8) ellipse {
  fill: #00ffff; /* Bright Cyan */
  opacity: 0.05;
  rx: 0;
  ry: 0;
  cx: 105px;
  cy: 48px;
  -webkit-animation: shadow 600ms infinite ease-in-out;
  animation: shadow 600ms infinite ease-in-out;
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}
.load:nth-of-type(9) ellipse {
  fill: #ff00ff; /* Bright Magenta */
  opacity: 0.05;
  rx: 0;
  ry: 0;
  cx: 145px;
  cy: 48px;
  -webkit-animation: shadow 600ms infinite ease-in-out;
  animation: shadow 600ms infinite ease-in-out;
  -webkit-animation-delay: 225ms;
  animation-delay: 225ms;
}
.load:nth-of-type(10) ellipse {
  fill: #00ff00; /* Bright Lime */
  opacity: 0.05;
  rx: 0;
  ry: 0;
  cx: 185px;
  cy: 48px;
  -webkit-animation: shadow 600ms infinite ease-in-out;
  animation: shadow 600ms infinite ease-in-out;
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}

@-webkit-keyframes jump {
  40% {
    transform: translateY(20px) scale(1.3);
    opacity: 0.9;
  }
  40% {
    rx: 10px;
    ry: 10px;
    stroke-width: 3px;
  }
  45% {
    rx: 15px;
    ry: 7px;
    stroke-width: 4px;
  }
  55% {
    rx: 10px;
    ry: 10px;
  }
}

@keyframes jump {
  40% {
    transform: translateY(20px) scale(1.3);
    opacity: 0.9;
  }
  40% {
    rx: 10px;
    ry: 10px;
    stroke-width: 3px;
  }
  45% {
    rx: 15px;
    ry: 7px;
    stroke-width: 4px;
  }
  55% {
    rx: 10px;
    ry: 10px;
  }
}
@-webkit-keyframes shadow {
  45% {
    opacity: 0.15;
    rx: 10px;
    ry: 3px;
    transform: translateY(0px) scale(1.3);
  }
}
@keyframes shadow {
  45% {
    opacity: 0.15;
    rx: 10px;
    ry: 3px;
    transform: translateY(0px) scale(1.3);
  }
}
