@import url(https://fonts.googleapis.com/css?family=Lato);

.hide_overFlow {
  overflow-x: scroll;
}

.swiper-button-prev,
.swiper-button-next {
  color: white !important; /* Change the color to your desired color */
  font-size: 24px; /* Adjust the font size if needed */
}

/* Optional: Hover styles for navigation buttons */
.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: grey !important; /* Change the color on hover */
}

.custom-swiper-slide {
  z-index: 10;
  transition: all 0.3s ease;
}

.custom-swiper-slide:hover { 
  transform: scale(1.50);
  z-index: 100;
}