
.ribbon-3 {
      position: absolute;
      left: -15px;
      bottom: 20%;
      z-index: 3;
      color: white;
      background: linear-gradient(93deg, #052DB0 14.59%, #C30280 98.03%);
      width: 82px;
      height: 37px;
      clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%); 
      padding-left: 10px;
}

.back_drop {
      position: absolute;
      /* z-index: 2; */
      left: -15px;
      bottom: 10%;
      color: white;
      background: #031D71;

      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 40% 100%); 
      width: 40px;
      height: 20px;


}